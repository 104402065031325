.mf-cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 250;
  direction: ltr;
  contain: layout style size;
  pointer-events: none;
  transition: opacity 0.3s, color 0.4s;
}

.mf-cursor:before {
  content: "";
  position: absolute;
  top: -2.4rem;
  left: -2.4rem;
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  transform: scale(0.2);
  background: var(--text-primary);
  border-radius: 50%;
  transition: transform 0.25s ease-in-out, opacity 0.1s;
}

.mf-cursor:after {
  transform: scale(0);
  background: var(--background-primary);
}

.mf-cursor.-inverse:before {
  background: var(--background-primary);
}

.mf-cursor.-after:after {
  transform: scale(2);
}

.mf-cursor.-exclusion {
  mix-blend-mode: exclusion;
}

.mf-cursor.-pointer:before {
  transform: scale(0.15);
}

.mf-cursor.-text:before {
  opacity: 0.85;
  transform: scale(1.7);
}

.mf-cursor.-text.-active:before {
  transform: scale(1.6);
  transition-duration: 0.2s;
}

.mf-cursor.-icon:before {
  transform: scale(1.5);
}

.mf-cursor.-icon.-active:before {
  transform: scale(1.4);
}

.mf-cursor.-hidden:before {
  transform: scale(0);
}

.mf-cursor.-opaque:before {
  opacity: 0.2;
}

.mf-cursor-text {
  position: absolute;
  top: -1.8rem;
  left: -1.8rem;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0) rotate(10deg);
  opacity: 0;
  color: var(--background-primary);
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  transition: opacity 0.4s, transform 0.3s;
}

.mf-cursor.-text .mf-cursor-text,
.mf-cursor.-icon .mf-cursor-text {
  opacity: 1;
  transform: scale(1);
}

.mf-cursor-media {
  position: absolute;
  width: 40rem;
  height: 40rem;
  margin: -20rem 0 0 -20rem;
}

.mf-cursor-media img,
.mf-cursor-media video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@supports (object-fit: cover) {
  .mf-cursor-media img,
  .mf-cursor-media video {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateZ(0);
  }
}

.mf-cursor-media-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: scale(0) translateZ(0);
  padding: 0.1rem;
  opacity: 0;
  border-radius: 50%;
  transition: transform 0.35s, opacity 0.2s;
}

.mf-cursor.-media .mf-cursor-media-box {
  mix-blend-mode: screen;
  opacity: 1;
  transform: scale(1);
  transition-duration: 0.4s;
  transition-delay: 0s;
}

.mf-cursor.-top-button:before {
  width: 4rem;
  height: 4rem;
  transform: scale(1);
}

.mf-cursor.-sm:before {
  transform: scale(0.6);
}

.mf-cursor.-base:before {
  transform: scale(1);
}

.mf-cursor.-l:before {
  transform: scale(1.5);
}

.mf-cursor.-xl:before {
  transform: scale(2);
}

.mf-cursor.-xxl:before {
  transform: scale(3);
}
