@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --z-header: 15;
  --z-marquee: 15;
  --z-loader: 20;

  --text-muted: rgba(148, 161, 178, 1);
  --background-primary: rgba(22, 22, 26, 1);
  --background-secondary: rgba(36, 38, 41, 1);
  --text-primary: rgba(255, 255, 255, 1);
  --text-secondary: rgba(114, 117, 126, 1);
  --border-color: rgba(1, 1, 1, 1);
  --accent-color: rgba(255, 99, 0, 1);

  --size: 375;

  --ease-cubic: cubic-bezier(0.126, 0.879, 0.563, 1.003);

  --text-big: calc(4rem + (70 - 40) * ((100vw - 320px) / (1280 - 320)));
}

@media screen and (min-width: 768px) {
  :root { --size: 768; }
}

@media screen and (min-width: 1024px) {
  :root { --size: 1024; }
}

@media screen and (min-width: 1280px) {
  :root { --size: 1280; }
}

@media screen and (min-width: 1536px) {
  :root { --size: 1536; }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-muted: rgba(148, 161, 178, 1);
    --background-primary: rgba(22, 22, 26, 1);
    --background-secondary: rgba(36, 38, 41, 1);
    --text-primary: rgba(255, 255, 255, 1);
    --text-secondary: rgba(114, 117, 126, 1);
    --border-color: rgba(1, 1, 1, 1);
    --accent-color: rgba(255, 99, 0, 1);
  }
}

html { font-size: calc(10 * (100vw / var(--size))); }

body {
  overflow-x: hidden;
  color: var(--text-muted);
  background-color: var(--background-primary);
}

.word { display: inline-flex; }

@layer utilities {
  .text-balance { text-wrap: balance; }

  .upper { text-transform: uppercase; }

  .clip { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

  .richtext strong { color: var(--text-primary); }
}
